'use client';
import Link from 'next/link';
import { Socials } from '~/ui/socials';
import { Button } from '~/ui/button';
import { Card, CardContent, CardHeader } from '~/ui/card';

function Error() {
  return (
    <div className='relative w-screen h-screen overflow-hidden overscroll-none '>
      <Card className='max-w-[300px] md:max-w-[600px] absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
        <CardHeader>
          <span>Error</span>
        </CardHeader>
        <CardContent className='flex flex-col items-center justify-center w-full h-full gap-4 py-4 bg-gray-950'>
          <span className='text-base '>An Error Has Occurred</span>

          <div className='flex items-center gap-2 p-2 py-4 text-lg uppercase'>
            <Button variant='default' className='p-8'>
              <img
                className='w-6 h-6 '
                src='https://immutable-cdn.s3.us-east-2.amazonaws.com/logo/token.svg'
                alt='IMT'
              />
              <Link href={'./posts'}>Back to Immutable</Link>
            </Button>
          </div>

          <div>
            <Socials />
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default Error;
